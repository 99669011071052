<template>
  <div class="content">
    <PageHeader title="FLOWS" :subtitle="$tc('app.report', 2)" />
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-lg-12 col-xl-12">
          <div class="card card-tabs">
            <div class="card-header">
              <ul class="nav nav-tabs">
                <li class="nav-item" role="presentation">
                  <router-link :to="{ name: 'FlowStatReport' }" class="nav-link" replace><span class="material-symbols-outlined"> query_stats </span>{{ $tc('app.stats', 2) }}</router-link>
                </li>
                <li class="nav-item" role="presentation">
                  <router-link :to="{ name: 'FlowHistoryReport' }" class="nav-link" replace><span class="material-symbols-outlined"> history </span>{{ $t('app.history') }}</router-link>
                </li>
                <!--<li class="nav-item" role="presentation">
                  <router-link
                    :to="{ name: 'FlowUsageReport' }"
                    class="nav-link"
                    replace
                    ><span class="material-symbols-outlined">
                      insert_chart </span
                    >{{ $tc('app.usage', 1) }}</router-link
                  >
                </li>-->
                <li class="nav-item" role="presentation">
                  <router-link :to="{ name: 'FlowReportExports' }" class="nav-link" replace><span class="material-symbols-outlined"> cloud_download </span>{{ $tc('app.file', 2) }}</router-link>
                </li>
                <div class="opts_card">
                  <a style="display: flex; align-items: center" href="javascript:void(0)" @click="showFilter"
                    ><span class="material-symbols-outlined title">
                      filter_list
                    </span></a
                  >
                  <a style="display: flex; align-items: center" href="javascript:void(0)" @click="reload"
                    ><span class="material-symbols-outlined title">
                      refresh
                    </span></a
                  >
                  <form class="form-inline calendar_form">
                    <date-range-picker class="form-control" :format="'YYYY-MM-DD'" :options="options" @change="changeDate" />
                  </form>
                  <span class="material-icons title calendar_icon">
                    calendar_today
                  </span>
                  <b-dropdown variant=" " class="export-opt" :right="true" no-caret>
                    <template #button-content>
                      <span class="material-icons-outlined title">file_download</span>
                    </template>
                    <template v-if="isFlowStats">
                      <b-dropdown-item @click="showDialog('csv')">
                        CSV
                      </b-dropdown-item>
                      <b-dropdown-item @click="showDialog('png')">
                        PNG
                      </b-dropdown-item>
                      <b-dropdown-item @click="showDialog('svg')">
                        SVG
                      </b-dropdown-item>
                    </template>
                    <template v-else>
                      <b-dropdown-item @click="exportData('csv')">
                        CSV
                      </b-dropdown-item>
                      <b-dropdown-item @click="exportData('pdf')">
                        PDF
                      </b-dropdown-item>
                      <b-dropdown-item @click="exportData('xlsx')">
                        XLSX
                      </b-dropdown-item>
                    </template>
                  </b-dropdown>
                  <!-- <Button @click="visible = !visible">Click</Button> -->
                  <!-- <dropdown tag="span">
                    <template #text> </template>
                    <template #items>
                      <a
                        class="dropdown-item"
                        href="javascript:void(0)"
                        @click="exportData('csv')"
                        >CSV</a
                      >
                      <a
                        v-if="$route.name === 'FlowStatReport'"
                        class="dropdown-item"
                        href="javascript:void(0)"
                        @click="exportData('png')"
                        >PNG</a
                      >
                      <a
                        v-if="$route.name === 'FlowStatReport'"
                        class="dropdown-item"
                        href="javascript:void(0)"
                        @click="exportData('svg')"
                        >SVG</a
                      >
                      <a
                        v-if="$route.name !== 'FlowStatReport'"
                        class="dropdown-item"
                        href="javascript:void(0)"
                        @click="exportData('xlsx')"
                        >XLSX</a
                      >
                      <a
                        v-if="$route.name !== 'FlowStatReport'"
                        class="dropdown-item"
                        href="javascript:void(0)"
                        @click="exportData('pdf')"
                        >PDF</a
                      >
                    </template>
                  </dropdown> -->
                </div>
              </ul>
            </div>
            <div class="card-body block-el">
              <router-view ref="view" @filter="changeFlow" :filters="form.filters" :start-date="form.date.gte" :end-date="form.date.lte" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <SidebarComponent ref="filter" title="Filtros">
      <InputComponent :name="$t('app.name')" type="text" :label="$tc('app.name', 1)" placeholder="" v-model="filter.name"></InputComponent>
      <SelectComponent v-model="filter.flowId" :label="$tc('app.flow', 1)" :options="flows" text-field="name" value-field="id"></SelectComponent>
      <SelectComponent v-model="filter.channelId" :label="$tc('app.channel', 1)" :options="channels" text-field="name" value-field="id"></SelectComponent>
      <SelectComponent v-model="filter.status" :label="$tc('app.status', 1)" :options="statuses" multiple></SelectComponent>
      <template #footer>
        <div class="d-flex justify-content-between">
          <ButtonComponent @click="cleanFilter" variant="secondary">Limpar</ButtonComponent>
          <ButtonComponent @click="applyFilter">Aplicar Filtros</ButtonComponent>
        </div>
      </template>
    </SidebarComponent>
    <Dialog ref="dialog" :visible="visible" modal :style="{ width: '25rem' }">
      <div class="card">
        <div class="card-header">
          <h3>Exportar</h3>
          <button class="btn" @click="visible = false"><span class="material-symbols-outlined">close</span></button>
        </div>
        <div class="card-body">
          <ul class="list-unstyled pt-3 mb-0">
            <li>
              <div class="control-group toggle-check opt-2">
                <label class="control control-checkbox" for="chartStats">
                  <input v-model="selectedChart.label" type="radio" class="custom-control-input" id="chartStats" value="chartStats" />
                  <div class="control_indicator"></div>
                  <span>Flows por Status</span>
                </label>
              </div>
            </li>
            <li>
              <div class="control-group toggle-check opt-2">
                <label class="control control-checkbox" for="chartType">
                  <input v-model="selectedChart.label" type="radio" class="custom-control-input" id="chartType" value="chartType" />
                  <div class="control_indicator"></div>
                  <span>Flows por Tipo</span>
                </label>
              </div>
            </li>
            <li>
              <div class="control-group toggle-check opt-2">
                <label class="control control-checkbox" for="chartChannel">
                  <input v-model="selectedChart.label" type="radio" class="custom-control-input" id="chartChannel" value="chartChannel" />
                  <div class="control_indicator"></div>
                  <span>Flows por Canal</span>
                </label>
              </div>
            </li>
          </ul>
        </div>
        <div class="card-footer justify-content-end">
          <button class="btn btn-outline-primary" @click="visible = false">{{ $t('app.cancel') }}</button>
          <button class="btn btn-primary" @click="exportData(format)">{{ $t('app.save') }}</button>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment';
import PageHeader from '@/components/rebranding/PageHeader.vue';
// import Dropdown from '@/components/Dropdown';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import Dialog from 'primevue/dialog';
// import Button from 'primevue/button';
// import Avatar from 'primevue/avatar';
import Tab from '@/directives/Tab';
import axios from '@/plugins/axios';
import InputComponent from '@/components/form/InputComponent.vue';
import ButtonComponent from '@/components/ui/ButtonComponent.vue';
import SidebarComponent from '@/components/SidebarComponent.vue';
import SelectComponent from '@/components/form/SelectComponent.vue';

const webApi = axios();

export default {
  name: 'FlowDashboard',
  components: {
    PageHeader,
    // Dropdown,
    // Card,
    // Avatar,
    // Button,
    Dialog,
    InputComponent,
    ButtonComponent,
    SidebarComponent,
    SelectComponent,
  },
  directives: {
    Tab,
  },
  data() {
    return {
      statuses: [
        {
          text: this.$tc('app.agent_took', 1),
          value: 'AGENT_TOOK',
        },
        {
          text: this.$tc('app.canceled', 1),
          value: 'CANCELED',
        },
        {
          text: this.$tc('app.executing', 1),
          value: 'EXECUTING',
        },
        {
          text: this.$tc('app.failed', 1),
          value: 'FAILED',
        },
        {
          text: this.$tc('app.finished', 1),
          value: 'FINISHED',
        },
        {
          text: this.$tc('app.time_limit_exceeded', 1),
          value: 'TIME_LIMIT_EXCEEDED',
        },
        {
          text: this.$tc('app.transferred', 1),
          value: 'TRANSFERRED',
        },
        {
          text: this.$tc('app.waiting', 1),
          value: 'WAITING',
        },
      ],
      flows: [],
      filter: {
        name: '',
        flowId: '',
      },
      selectedChart: {
        label: 'chartStats',
        value: 'lineChart',
      },
      format: 'csv',
      visible: false,
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        startDate: moment().format('DD/MM/YYYY'),
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Otu', 'Nov', 'Dez'],
        },
      },
      form: {
        category: 'flows',
        date: {
          gte: moment().format('YYYY-MM-DD'),
          lte: moment().format('YYYY-MM-DD 23:59:59'),
        },
        filters: {
          name: '',
          flowId: '',
        },
      },
      campaigns: [],
      channels: [
        {
          id: process.env.VUE_APP_INSTAGRAM_ID,
          name: 'Instagram',
        },
        {
          id: process.env.VUE_APP_WHATS_META,
          name: 'WhatsApp',
        },
        {
          id: process.env.VUE_APP_MESSENGER_ID,
          name: 'Messenger',
        },
        {
          id: process.env.VUE_APP_TELEGRAM_ID,
          name: 'Telegram',
        },
        {
          id: process.env.VUE_APP_WIDGET_ID,
          name: 'Widget',
        },
        {
          id: process.env.VUE_APP_RCS_ID,
          name: 'RCS',
        },
      ],
    };
  },
  computed: {
    isFlowStats() {
      return this.$route.name === 'FlowStatReport';
    },
    account() {
      return this.$store?.state?.account;
    },
  },
  watch: {
    visible: {
      handler(val, oldVal) {
        console.log('Dialog: ', this.$refs.dialog);
      },
    },
  },
  mounted() {
    this.$refs.dialog.$el.addEventListener('click', () => {
      console.log('Click');
      this.$refs.dialog.close();
    });
  },
  created() {
    this.fetchFlows();
  },
  methods: {
    showDialog(format) {
      this.visible = !this.visible;
      this.format = format;
    },
    fetchFlows() {
      this.$store.dispatch('fetchFlows').then((response) => {
        this.flows = response;
      });
    },
    showFilter() {
      this.$refs.filter.toggle();
    },
    reload() {
      this.$refs.view.fetch();
    },
    applyFilter() {
      this.form.filters = this.filter;
      this.$nextTick(() => {
        this.reload();
      });
      this.$refs.filter.toggle();
    },
    changeFlow(route, flowId) {
      this.filter.flowId = flowId;
      this.$router.push(route);
      this.applyFilter();
    },
    cleanFilter() {
      this.$refs.filter.toggle();
      this.filter = {
        name: '',
        flowId: '',
        channelId: '',
      };
      this.form.filters = {
        flowId: '',
        name: '',
      };
      this.$nextTick(() => {
        this.reload();
      });
    },
    changeDate(date) {
      if (date) {
        this.form.date.gte = date[0];
        this.form.date.lte = moment(date[1]).format('YYYY-MM-DD 23:59:59');

        this.$nextTick(() => {
          this.reload();
        });
      }
    },
    exportData(format) {
      // console.log('View: ', this.isFlowStats);
      if (this.isFlowStats) {
        this.$refs.view.exportData(format, this.selectedChart, this.isFlowStats);
        this.visible = !this.visible;
      } else {
        webApi
          .post(
            `/flow/report/generate?query[]=creation_date;gte;'${this.form.date.gte}'&query[]=creation_date;lte;'${this.form.date.lte}'&query[]=account_id;eq;'${this.account.id}'&format=${format}&page=0&orderBy=creation_date;desc`,
          )
          .then(
            (response) => {
              console.log('Flow Export: ', response);
              this.loading = false;
              this.files = response.data.content;
              this.pages = response.data.totalPages;
              this.$router.push({ name: 'FlowReportExports' });
            },
            (error) => {
              this.loading = false;
              this.content = error;
              this.$toast.show({
                title: 'Erro',
                content: 'Erro interno, por favor tente novamente mais tarde ou contate o suporte para ajuda.',
                type: 'danger',
              });
            },
          );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~primevue/resources/themes/saga-blue/theme.css';
@import '~primevue/resources/primevue.min.css';

.table-responsive {
  min-height: 150px;
}

/* Rebranding */
.card.card-tabs .card-header .nav.nav-tabs {
  width: 100%;
  padding: 0 1rem;
  gap: 2.5rem;
}
.card.card-tabs .card-header .nav.nav-tabs li a {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 1.4rem 0.7rem 1.3rem;
  color: var(--clr-light-2);
}
.nav.nav-tabs .nav-item .nav-link.router-link-exact-active {
  color: var(--clr-yup-purple) !important;
  border-bottom: solid 2px var(--clr-yup-purple);
}

.nav.nav-tabs .nav-item .nav-link.router-link-exact-active i {
  color: var(--clr-yup-purple) !important;
}

.card.card-tabs .card-header .nav.nav-tabs li a i {
  font-size: 24px;
}

.nav-tabs .nav-item {
  margin-bottom: -2px;
}
.nav-tabs {
  border-bottom: 2px solid rgba(210, 221, 234, 0.5);
}

.rebranding .card .opts_card i {
  font-size: 22px;
  transition: 0.5s;
  padding: 5px;
}

.rebranding .card .opts_card i:hover {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.calendar_form {
  position: absolute;
  right: 70px;
  width: 38px;
  opacity: 0;
}

.calendar_form:hover ~ .calendar_icon,
.calendar_form:focus-within ~ .calendar_icon {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.calendar_form input {
  cursor: pointer;
  width: 38px;
}

::v-deep {
  .export-opt {
    button {
      width: fit-content;
      height: fit-content;
      padding: 0;
    }
    ul.dropdown-menu {
      a {
        height: fit-content !important;
        padding: 10px 20px !important;
      }
    }
  }
  .p-dialog {
    .p-dialog-header {
      display: none;
    }
    .card {
      box-shadow: none;
      margin-bottom: 0;
      .card-header,
      .card-body,
      .card-footer {
        padding: 0;
      }
      .card-header {
        padding-top: 2rem;
        align-items: center;
        h1,
        h2,
        h3,
        h4,
        h5 {
          margin-bottom: 0;
          height: fit-content;
        }
        button {
          padding: 0;
          height: fit-content;
          margin-left: auto;
          span {
            color: var(--gray-font-color-2);
          }
        }
      }
      .card-body {
        margin-bottom: 1rem;
      }
      .card-footer {
        display: flex;
        gap: 0.5rem;
      }
    }
  }
}
</style>
